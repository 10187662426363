<template>
  <div class="layout-menu-container">
    <AppSubmenu
      :items="items"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
    />
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
  created() {
    // Start static items
    this.items.push({
      label: "Inicio",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/",
        }
      ],
    });

    //Dinamic organization items
    const organizations = this.$store.state.user.organizations;
    organizations.forEach(({ name, id }) => {
      this.items.push({
        label: name,
        icon: "pi pi-fw pi-sitemap",
        items: [
          {
            label: "Reservas",
            icon: "pi pi-fw pi-bookmark",
            to: `/organization/${id}`,
          },
          {
            label: "Plantillas de notificaciones",
            icon: "pi pi-fw pi-envelope",
            items: [
              {
                label: "Reserva creada por cliente",
                icon: "pi pi-fw pi-plus",
                to: `/organization/${id}/notification/CREATE_BOOKING_MANUAL`,
              },
              {
                label: "Reserva creada por punto de venta",
                icon: "pi pi-fw pi-plus",
                to: `/organization/${id}/notification/CREATE_BOOKING_AUTO`,
              },
              {
                label: "Reserva automática aceptada",
                icon: "pi pi-fw pi-check",
                to: `/organization/${id}/notification/ACCEPT_BOOKING`,
              },
              {
                label: "Reserva manual aceptada",
                icon: "pi pi-fw pi-check",
                to: `/organization/${id}/notification/ACCEPT_BOOKING_MANUAL`,
              },
              {
                label: "Reserva rechazada",
                icon: "pi pi-fw pi-times",
                to: `/organization/${id}/notification/REJECT_BOOKING`,
              },
              {
                label: "Reserva cancelada",
                icon: "pi pi-fw pi-ban",
                to: `/organization/${id}/notification/CANCEL_BOOKING`,
              },
              {
                label: "Recordatorio de reserva",
                icon: "pi pi-fw pi-calendar-plus",
                to: `/organization/${id}/notification/REMIND_BOOKING`,
              },
            ],
          },
          {
            label: "Widget",
            icon: "pi pi-fw pi-briefcase",
            to: `/organization/${id}/widget`,
          },
          {
            label: "Historial de notificaciones",
            icon: "pi pi-fw pi-briefcase",
            to: `/organization/${id}/history`,
          },
          {
            label: "Configuración",
            icon: "pi pi-fw pi-cog",
            items: [
              {
                label: "Notificaciones",
                icon: "pi pi-fw pi-envelope",
                to: `/organization/${id}/config/notifications`,
              },
              {
                label: "Disponibilidad",
                icon: "pi pi-fw pi-clock",
                to: `/organization/${id}/config/calendar`,
              },
              {
                label: "Formulario de reserva",
                icon: "pi pi-fw pi-inbox",
                to: `/organization/${id}/config/form`,
              },
              {
                label: "Punto de venta",
                icon: "pi pi-fw pi-desktop",
                to: `/organization/${id}/config/organization`,
              },
            ],
          },
          {
            label: "Lista negra",
            icon: "pi pi-fw pi-ban",
            to: `/organization/${id}/blacklist`,
          },
        ],
      });
    });
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
};
</script>
