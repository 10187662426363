/* eslint-disable no-dupe-class-members */
class AcutameUriBuilder {
  #BASE_PATH = '/api';

  #ORGANIZATION_PATH = `${this.#BASE_PATH}/organization`;
  #CONFIG_PATH = '/config';
  #AVAILABLE_PATH = '/available';
  #FORM_FIELD_PATH = '/form/field';

  #BOOKING_PATH = `${this.#BASE_PATH}/booking`;

  #MERCHANT_PATH = `${this.#BASE_PATH}/merchant`;
  #PUBLIC_MERCHANT_PATH = `${this.#BASE_PATH}/public/merchant`;
  #PASSWROD_RECOVERY_PATH = `${this.#PUBLIC_MERCHANT_PATH}/password`;
  #PRICING_PATH = `${this.#BASE_PATH}/pricing`;

  #composeConfigPath(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}${this.#CONFIG_PATH}`;
  }
  #composeAvailablePath(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}${
      this.#AVAILABLE_PATH
    }`;
  }
  #composeFormFieldPath(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}${
      this.#FORM_FIELD_PATH
    }`;
  }
  #composeFormPath(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}/form`;
  }

  createMerchantUri() {
    return `${this.#BASE_PATH}/public/merchant`;
  }

  isEmailAvailableUri(email){
    return `${this.#BASE_PATH}/public/merchant/available?email=${email}`;
  }

  createOrganizationUri() {
    return this.#ORGANIZATION_PATH;
  }
  getOrganizationUri() {
    return this.#ORGANIZATION_PATH;
  }
  changeOrganizationEnabledStateUri(id, active) {
    return `${this.#ORGANIZATION_PATH}/${id}/active?enabled=${active}`;
  }
  changeOrganizationsEnabledStateUri(){
    return `${this.#ORGANIZATION_PATH}/active`;
  }
  getOrganizationConfigUri(organizationId) {
    return this.#composeConfigPath(organizationId);
  }
  getMerchantOrganizationsUri() {
    return `${this.#MERCHANT_PATH}/list`;
  }
  updateBookingConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/booking`;
  }
  updateBookingLimitConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/booking/limit`;
  }
  updateHolidayConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/holiday`;
  }
  updatAvailabilityConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/availability`;
  }
  addFullDayConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/capacity/manual`;
  }
  updateNotificationConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/notifications`;
  }
  updateOrganizationConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}`;
  }
  uploadOrganizationLogoUri(organazitionId) {
    return `${this.#ORGANIZATION_PATH}/${organazitionId}/logo`;
  }
  updateRemindersUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/reminders`;
  }
  updateSingleNotificationConfigUri(organizationId) {
    return `${this.#composeConfigPath(organizationId)}/notification`;
  }
  updateAutoacceptUri(organazitionId) {
    return `${this.#composeConfigPath(organazitionId)}/booking/accept`;
  }
  updateAdvanceBookingTime(organazitionId) {
    return `${this.#composeConfigPath(organazitionId)}/booking/advance`;
  }
  updateCancelBookingTime(organazitionId) {
    return `${this.#composeConfigPath(organazitionId)}/booking/cancelTime`;
  }
  updateCustomCssUri(organazitionId) {
    return `${this.#composeFormPath(organazitionId)}/customCss`;
  }
  getAvailableDatesUri(organizationId) {
    return `${this.#composeAvailablePath(organizationId)}/date`;
  }
  getAvailableTimesInDayUri(organizationId) {
    return `${this.#composeAvailablePath(organizationId)}/time`;
  }
  getTemplateKeywordsUri() {
    return `${this.#ORGANIZATION_PATH}/template/keywords`;
  }
  getFormFieldUri(organizationId) {
    return this.#composeFormFieldPath(organizationId);
  }
  createFormFieldUri(organazitionId) {
    return this.#composeFormFieldPath(organazitionId);
  }
  putFormFieldUri(organizationId, fieldName) {
    return `${this.#composeFormFieldPath(organizationId)}/${fieldName}`;
  }
  deleteFormFieldUri(organizationId, fieldName) {
    return `${this.#composeFormFieldPath(organizationId)}/${fieldName}`;
  }
  getCustomerByPhoneUri(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}/customers`;
  }
  searchCustomersUri(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}/customers/search`
  }
  blacklistCustomerUri(organizationId) {
    return `${this.#ORGANIZATION_PATH}/${organizationId}/customers/blacklist`
  }

  createBookingUri() {
    return `${this.#BOOKING_PATH}/manual/create`;
  }
  editBookingUri(bookingId) {
    return `${this.#BOOKING_PATH}/${bookingId}`;
  }
  handleBookingUri(bookingId) {
    return `${this.#BOOKING_PATH}/${bookingId}/handle`;
  }
  cancelBookingUri(bookingId) {
    return `${this.#BOOKING_PATH}/${bookingId}/cancel`;
  }
  listBookingsUri() {
    return `${this.#BOOKING_PATH}/list`;
  }

  meUri() {
    return this.#MERCHANT_PATH;
  }

  updateMeConfigUri() {
    return this.#MERCHANT_PATH + '/config';
  }

  loginUri() {
    return `${this.#BASE_PATH}/auth/login`;
  }

  sendRecoveryEmailUri() {
    return `${this.#PASSWROD_RECOVERY_PATH}/recovery`;
  }

  changePassowrdUri() {
    return `${this.#PASSWROD_RECOVERY_PATH}/change`;
  }

  addOrganizationsUri() {
    return `${this.#PRICING_PATH}/organization/add`;
  }

  buyPlanUri(plan) {
    return `${this.#PRICING_PATH}/plan/${plan}`;
  }

  activatePlanUri() {
    return `${this.#PRICING_PATH}/plan/activate`;
  }

  cancelPlanUri() {
    return `${this.#PRICING_PATH}/plan/cancel`;
  }

  getPricingPlans() {
    return `${this.#BASE_PATH}/public/price/plan/`;
  }

  getPricingSms() {
    return `${this.#BASE_PATH}/public/price/sms/`;
  }

  updatePricingPlan(paymentId) {
    return `${this.#BASE_PATH}/pricing/plan/update/${paymentId}`;
  }

  buySms() {
    return `${this.#PRICING_PATH}/sms/`;
  }

  getNotificationPageUrl(organizationId, page, size, sort, direction, method) {
    const url = `${
      this.#ORGANIZATION_PATH
    }/${organizationId}/notification?page=${page}&size=${size}&sort=${sort}&sortdir=${direction}`;
    return method ? `${url}&method=${method}` : url;
  }

  getDismissAlertUri(alertId) {
    return `${this.#BASE_PATH}/merchant/alert/${alertId}/dismiss`;
  }
}

export default new AcutameUriBuilder();
