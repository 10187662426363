import ky from 'ky';
import acutameUriBuilder from '../../utils/acutameUriBuilder';
import { getToken } from '../../utils/auth';

const getAuthorizationConfig = () => {
  const authToken = `Bearer ${getToken()}`;
  return {
    headers: {
      Authorization: authToken,
    },
  };
};

/**
 *
 * Acutame API by codefuente
 *
 * @author Adrian Rodil <adrian@codefuente.com>
 *
 */
export default {
  /**
   *
   * Check if a merchant with a given email already exists
   *
   * @param {string} email
   * @returns {Promise<{available: boolean}>}
   */
  async isEmailAvailable(email) {
    return await ky.get(acutameUriBuilder.isEmailAvailableUri(email)).json();
  },
  /**
   *
   * Create a new merchant
   *
   * @param {CreateMerchantDto} merchant
   * @returns Promise with the logged merchant info
   */
  async createMerchant(merchant) {
    return await ky
      .post(acutameUriBuilder.createMerchantUri(), {
        json: merchant,
      })
      .json();
  },
  /**
   *
   * Fetched logged merchant info
   *
   * @returns Promise with the logged merchant info
   */
  async me() {
    return await ky
      .get(acutameUriBuilder.meUri(), getAuthorizationConfig())
      .json();
  },
  /**
   *
   * @param {{ disableSmsAlerts: boolean }} newConfig
   * @returns Promise with the logged merchant info
   */
  async updateConfig(newConfig) {
    return await ky
      .put(acutameUriBuilder.updateMeConfigUri(), {
        ...getAuthorizationConfig(),
        json: newConfig,
      })
      .json();
  },
  /**
   *
   * Attempt login
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise<string>} JWT Token
   */
  async login(email, password) {
    const loggedUser = await ky
      .post(acutameUriBuilder.loginUri(), {
        json: { email, password },
      })
      .json();
    return loggedUser.token;
  },
  /**
   *
   * Get bookings for an organization
   *
   * @param {string} organizationId
   * @param {?number} page - Page number
   * @param {?number} size - Page size
   * @param {?showHistory} boolean - Get past bookings
   * @param {?{field:string, direction: string}} sort - Sort object
   * @param {?string} query - String to filter
   *
   * @returns {Promise<BookingPageDto>}
   */
  async getBookings(
    organizationId,
    page = 0,
    size = 20,
    sort,
    showHistory = false,
    query
  ) {
    return await ky
      .post(acutameUriBuilder.listBookingsUri(), {
        ...getAuthorizationConfig(),
        json: { organizationId, page, size, sort, query, showHistory },
      })
      .json();
  },
  /**
   *
   * @param {ManualBookingDto} booking
   * @param {string} bookingId
   * @returns {Promise<Booking>}
   */
  async editBooking(booking, bookingId) {
    return await ky
      .put(acutameUriBuilder.editBookingUri(bookingId), {
        ...getAuthorizationConfig(),
        json: booking,
      })
      .json();
  },
  /**
   * Create booking manually (By merchant)
   *
   * @param {ManualBookingDto} booking
   * @returns {Promise<Booking>}
   */
  async postBooking(booking) {
    return await ky
      .post(acutameUriBuilder.createBookingUri(), {
        ...getAuthorizationConfig(),
        json: booking,
      })
      .json();
  },
  /**
   * Accept a booking
   *
   * @param {Booking} booking
   * @returns {Promise<Booking>}
   */
  async acceptBooking(booking) {
    return await ky
      .get(
        `${acutameUriBuilder.handleBookingUri(booking.id)}?accept=true`,
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   * Reject a booking
   *
   * @param {Booking} booking
   * @returns {Promise<Booking>}
   */
  async rejectBooking(booking) {
    return await ky
      .get(
        `${acutameUriBuilder.handleBookingUri(booking.id)}?accept=false`,
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * @param {string} bookingId
   * @returns {Promise<Booking>}
   */
  async cancelBooking(bookingId) {
    return await ky
      .post(
        `${acutameUriBuilder.cancelBookingUri(bookingId)}`,
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * Get an organization by id
   *
   * @param {string} organizationId
   * @returns {Promise<Organization>}
   */
  async getOrganization(organizationId) {
    return await ky
      .get(
        `${acutameUriBuilder.getOrganizationUri()}?id=${organizationId}`,
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * Get an organization config by organization id
   *
   * @param {string} organizationId
   * @returns {Promise<OrganizationConfig>}
   */
  async getOrganizationConfig(organizationId) {
    return await ky
      .get(
        acutameUriBuilder.getOrganizationConfigUri(organizationId),
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * Create a new organization for the logged merchant
   *
   * @param {OrganizationConfigUpdate} organizationConfig
   */
  async createOrganization(organizationConfig) {
    return await ky
      .post(acutameUriBuilder.getOrganizationUri(), {
        ...getAuthorizationConfig(),
        json: organizationConfig,
      })
      .json();
  },
  /**
   *
   * Enable/disable an organization
   *
   * @param {string} organizationId
   * @param {boolean} active
   * @returns
   */
  async changeOrganizationEnabledState(organizationId, active) {
    return await ky
      .get(
        acutameUriBuilder.changeOrganizationEnabledStateUri(
          organizationId,
          active
        ),
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * Enable organizations that are on the list (disable the rest)
   *
   * @param {[string]} activeOrganizations
   * @returns
   */
  async toggleOrganizationsEnabledState(activeOrganizations) {
    return await ky.post(
      acutameUriBuilder.changeOrganizationsEnabledStateUri(),
      { ...getAuthorizationConfig(), json: activeOrganizations }
    );
  },
  /**
   * Get customer data by phone and dial number
   *
   * @param {String} organizationId
   * @param {String} phone
   * @param {String} dialCode
   * @returns {Promise<{name: string, phone: number, dialCode: number}[]>}
   */
  async getCustomerByPhone(organizationId, phone, dialCode) {
    return await ky
      .get(
        `${acutameUriBuilder.getCustomerByPhoneUri(
          organizationId
        )}?phone=${phone}&dialCode=${dialCode}`,
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   * 
   * Search customers based on a filter string
   * 
   * @param {*} organizationId 
   * @param {*} search filter string, matches eiher name, lastname, email or phone
   * @param {*} blacklisted if true only blacklisted customers are returned
   * @param {*} page 
   * @param {*} size 
   * @returns {Promise<[{firstname: string, lastname: string, phone: number, phonePrefix: number, email: string, blacklisted: boolean}]>}
   */
  async searchCustomer(organizationId, search, blacklisted, page, size) {
    return await ky
      .get(
        `${acutameUriBuilder.searchCustomersUri(
          organizationId
        )}?filter=${search}&blacklisted=${blacklisted}&page=${page}&size=${size}`,
        getAuthorizationConfig()
      )
      .json();
  },
/**
 * 
 * @param {*} organizationId 
 * @param {*} blacklistUpdate 
 * @returns {Promise<>}
 */
  async updateCustomerBlacklist(organizationId, blacklistUpdate ) {
    return await ky
      .post(
        acutameUriBuilder.blacklistCustomerUri(
          organizationId
        ),
        { ...getAuthorizationConfig(), json: blacklistUpdate }
      );
  },
  /**
   *
   * Get a list of organazitions that belong to the logged merchant
   *
   * @returns {Promise<OrganizationInfoDto[]>}
   */
  async getMerchantOrganizations() {
    return await ky
      .get(
        acutameUriBuilder.getMerchantOrganizationsUri(),
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * Updates bookingConfig of an organization
   *
   * @param {string} organizationId
   * @param {BookingConfig} bookingConfig
   * @returns {Promise<Organization>}
   */
  async putBookingConfig(organizationId, bookingConfig) {
    return await ky
      .put(acutameUriBuilder.updateBookingConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: bookingConfig,
      })
      .json();
  },
  /**
   *
   * Updates holidayConfig of an organization
   *
   * @param {string} organizationId
   * @param {HolidayConfig} newHolidays
   * @returns {Promise<Organization>}
   */
  async putHolidays(organizationId, newHolidays) {
    return await ky
      .put(acutameUriBuilder.updateHolidayConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: newHolidays,
      })
      .json();
  },
  /**
   *
   * Updates bookingLimitConfig of an organization
   *
   * @param {string} organizationId
   * @param {BookingLimitConfig} newBookingLimit
   * @returns {Promise<Organization>}
   */
  async putBookingLimit(organizationId, newBookingLimit) {
    return await ky
      .put(acutameUriBuilder.updateBookingLimitConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: newBookingLimit,
      })
      .json();
  },
  /**
   *
   * Updates notification config
   *
   * @param {string} organizationId
   * @param {NotificationConfig[]} newNotificationConfig
   * @returns {Promise<Organization>}
   */
  async putNotificationsConfig(organizationId, newNotificationConfig) {
    return await ky
      .put(acutameUriBuilder.updateNotificationConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: newNotificationConfig,
      })
      .json();
  },
  /**
   *
   * Updates a single notification config
   *
   * @param {string} organizationId
   * @param {NotificationConfig} newNotificationConfig
   * @returns {Promise<Organization>}
   */
  async putNotificationConfig(organizationId, newNotificationConfig) {
    return await ky
      .put(
        acutameUriBuilder.updateSingleNotificationConfigUri(organizationId),
        {
          ...getAuthorizationConfig(),
          json: newNotificationConfig,
        }
      )
      .json();
  },
  /**
   *
   * Updates availability config
   *
   * @param {string} organizationId
   * @param {RepeatingAvailabilityConfig} newAvailability
   * @returns {Promise<Organization>}
   */
  async putAvailabilityConfig(organizationId, newAvailability) {
    return await ky
      .put(acutameUriBuilder.updatAvailabilityConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: newAvailability,
      })
      .json();
  },
  /**
   *
   * Adds a full day
   *
   * @param {string} organizationId
   * @param {string} newFullDay
   * @returns {Promise<Organization>}
   */
  async addFullDays(organizationId, newFullDay) {
    return await ky
      .post(acutameUriBuilder.addFullDayConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: [newFullDay],
      })
      .json();
  },
  /**
   *
   * removes a full day
   *
   * @param {string} organizationId
   * @param {string} newFullDay
   * @returns {Promise<Organization>}
   */
  async removeFullDays(organizationId, newFullDay) {
    return await ky
      .delete(acutameUriBuilder.addFullDayConfigUri(organizationId), {
        ...getAuthorizationConfig(),
        json: [newFullDay],
      })
      .json();
  },
  /**
   *
   * Update organization basic settings i.e. name, tel etc.
   *
   * @param {string} organazitionId
   * @param {OrganizationConfigUpdate} newOrganizationConfig
   * @returns {Promise<Organization>}
   */
  async putOrganizationConfig(organazitionId, newOrganizationConfig) {
    return await ky
      .put(acutameUriBuilder.updateOrganizationConfigUri(organazitionId), {
        ...getAuthorizationConfig(),
        json: newOrganizationConfig,
      })
      .json();
  },
  /**
   *
   * @param {string} organizationId
   * @param {File} logo
   * @returns
   */
  async uploadOrganizationLogo(organizationId, logo) {
    const formData = new FormData();
    formData.append('img', logo);
    return await ky
      .post(acutameUriBuilder.uploadOrganizationLogoUri(organizationId), {
        ...getAuthorizationConfig(),
        body: formData,
      })
      .text();
  },
  /**
   *
   * Get template keywords for notification templates
   *
   * @returns {Promise<TemplateKeywordsDto>}
   */
  async getTemplateKeywords() {
    return ky
      .get(acutameUriBuilder.getTemplateKeywordsUri(), getAuthorizationConfig())
      .json();
  },

  /**
   *
   * Update notification reminders
   *
   * @param {string} organizationId
   * @param {BookingReminder[]} reminders
   * @returns {Promise<Organization>}
   */
  async putReminders(organizationId, reminders) {
    return ky
      .put(acutameUriBuilder.updateRemindersUri(organizationId), {
        ...getAuthorizationConfig(),
        json: reminders,
      })
      .json();
  },
  /**
   *
   * @param {string} organazitionId
   * @param {AcceptBookingsDto} updateDto
   * @returns
   */
  async putAutoaccept(organazitionId, updateDto) {
    return ky.put(acutameUriBuilder.updateAutoacceptUri(organazitionId), {
      ...getAuthorizationConfig(),
      json: updateDto,
    });
  },
  /**
   *
   * @param {string} organizationId
   * @param {number} time.hours
   * @returns {Promise<>}
   */
  async putAdvanceBookingTime(organizationId, time) {
    return ky.put(acutameUriBuilder.updateAdvanceBookingTime(organizationId), {
      ...getAuthorizationConfig(),
      json: time,
    });
  },
  /**
   *
   * @param {string} organizationId
   * @param {number} time.hours
   * @returns {Promise<>}
   */
  async putCancelBookingTime(organizationId, time) {
    return ky.put(acutameUriBuilder.updateCancelBookingTime(organizationId), {
      ...getAuthorizationConfig(),
      json: time,
    });
  },
  /**
   *
   * Update custom css for widget
   *
   * @param {string} organizationId
   * @param {string} customCss
   * @returns {Promise<Organization>}
   */
  async putCustomCss(organizationId, customCss) {
    return ky
      .put(acutameUriBuilder.updateCustomCssUri(organizationId), {
        ...getAuthorizationConfig(),
        json: customCss,
      })
      .json();
  },
  /**
   *
   * Gets custom form fields for an organization
   *
   * @param {string} organizationId
   * @returns {Promise<FormField[]>} array of custom form fields
   */
  async getFormFields(organizationId) {
    return ky
      .get(
        acutameUriBuilder.getFormFieldUri(organizationId),
        ...getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * @param {string} organazitionId
   * @param {FormField} formField
   * @returns {Promise<FormField[]>} array of custom form fields
   */
  async createFormField(organazitionId, formField) {
    return ky
      .post(acutameUriBuilder.createFormFieldUri(organazitionId), {
        ...getAuthorizationConfig(),
        json: formField,
      })
      .json();
  },
  /**
   *
   * Update a form field by name
   *
   * @param {string} organazitionId
   * @param {string} fieldName - form field current name
   * @param {Promise<FormField[]>} formField array of custom form fields
   * @returns {Promise<FormField[]>} array of custom form fields
   */
  async putFormField(organazitionId, fieldName, formField) {
    return ky
      .put(acutameUriBuilder.putFormFieldUri(organazitionId, fieldName), {
        json: formField,
        ...getAuthorizationConfig(),
      })
      .json();
  },
  /**
   *
   * Delete a form field by name
   *
   * @param {string} organizationId
   * @param {string} fieldName
   * @returns {Promise<FormField[]>} array of custom form fields
   */
  async deleteFormField(organizationId, fieldName) {
    return ky
      .delete(
        acutameUriBuilder.deleteFormFieldUri(organizationId, fieldName),
        getAuthorizationConfig()
      )
      .json();
  },
  /**
   *
   * Send a password recovery email
   *
   * @param {string} email Merchant email to recover password for
   */
  async sendRecoveryEmail(email) {
    return await ky.post(acutameUriBuilder.sendRecoveryEmailUri(), {
      body: email,
    });
  },
  /**
   *
   * Change merchant password
   *
   * @param {string} email Merchant email
   * @param {string} newPassword
   * @param {string} code Recovery code sent to the merchant email
   */
  async changePassword(email, newPassword, code) {
    return await ky.post(acutameUriBuilder.changePassowrdUri(), {
      json: { email, newPassword, code },
    });
  },
  /**
   *
   * Creates a new payment for adding a new organization to the merchant
   */
  async addOrganization() {
    return await ky.post(acutameUriBuilder.addOrganizationUri(), {
      json: {},
      ...getAuthorizationConfig(),
    });
  },
  /**
   *
   * Creates a new payment for adding a new organization to the merchant
   */
  async buyPlan(plan, additionalOrganizations) {
    return ky
      .post(acutameUriBuilder.buyPlanUri(plan), {
        json: { additionalOrganizations },
        ...getAuthorizationConfig(),
      })
      .json();
  },
  /**
   *
   * End trial and activate current subscription
   *
   * @returns
   */
  async activatePlan() {
    return ky
      .post(acutameUriBuilder.activatePlanUri(), getAuthorizationConfig())
      .json();
  },
  /**
   *
   * Cancel current subscription. Recurring charge will stop
   *
   * @returns
   */
  async cancelPlan() {
    return ky.post(acutameUriBuilder.cancelPlanUri(), getAuthorizationConfig());
  },
  /**
   *
   * Gets all non-trial pricing plans
   */
  async getPricingPlans() {
    return ky.get(acutameUriBuilder.getPricingPlans()).json();
  },
  /**
   *
   * Gets all sms packs
   */
  async getPricingSms() {
    return ky
      .get(acutameUriBuilder.getPricingSms(), {
        ...getAuthorizationConfig(),
      })
      .json();
  },
  async updatePricingPlan(paymentId) {
    return ky
      .get(acutameUriBuilder.updatePricingPlan(paymentId), {
        ...getAuthorizationConfig(),
      })
      .json();
  },
  /**
   *
   * Creates a new fixed sms payment charge
   *
   */
  async buySms(smsAmount) {
    return await ky
      .post(acutameUriBuilder.buySms(), {
        json: { smsAmount },
        ...getAuthorizationConfig(),
      })
      .json();
  },
  /**
   *
   * @param {string} organazitionId
   * @param {number} page history page number
   * @param {EMAIL | SMS} method filter notifications by method
   * @returns
   */
  async getNotificationHistory(
    organazitionId,
    page = 0,
    size = 50,
    sort = 'DATE',
    desc = true,
    method = null
  ) {
    return ky
      .get(
        acutameUriBuilder.getNotificationPageUrl(
          organazitionId,
          page,
          size,
          sort,
          desc ? 'DESC' : 'ASC',
          method
        ),
        getAuthorizationConfig()
      )
      .json();
  },
  async dismissAlert(alertId) {
    return ky
      .get(
        acutameUriBuilder.getDismissAlertUri(alertId),
        getAuthorizationConfig()
      )
      .json();
  },
};

/**
 * @typedef CreateMerchantDto
 * @property {string} firstname
 * @property {string} lastname
 * @property {string} email
 * @property {string} password
 */
/**
 * Organization object
 *
 * @typedef {Object} Organization
 * @property {string} id - organization id
 * @property {string} name - organization name
 * @property {OrganizationAddress} organizationAddress - organization address
 * @property {string} phone
 * @property {string} timezone
 * @property {string} logoLocation
 * @property {OrganizationConfig} config
 * @property {number} capacity
 * @property {string} phone
 */
/**
 * OrganizationAddress object
 *
 * @typedef {Object} OrganizationAddress
 * @property {number} story
 * @property {number} streetNumber
 * @property {string} street
 * @property {string} population
 * @property {string} province
 * @property {string} country
 * @property {string} zip
 *
 */
/**
 * OrganizationConfig object
 *
 * @typedef {Object} OrganizationConfig
 * @property {BookingConfig} bookingConfig
 * @property {HolidayConfig} holidayConfig
 * @property {NotificationConfig[]} notificationConfig - Notification configuration
 * @property {BookingReminder[]} reminders -  Bokking reminders configuration
 * @property {AvailabilityConfig} availabilityConfig - Config that determines when a date is bookeable
 *
 */
/**
 * BookingConfig object
 *
 * @typedef {Object} BookingConfig
 * @property {boolean} autoAcceptManualBooking - if this setting is true bookings created by a merchant will go to accepted state automatically
 * @property {boolean} autoAcceptAutomaticBooking - if this setting is true bookings created by a customer will go to accepted state automatically
 * @property {BookingFormConfig} bookingFormConfig - form fields for widget
 *
 */
/**
 * HolidayConfig object
 *
 * @typedef {Object} HolidayConfig
 * @property {{holidayStartDate: string, holidayEndDate: string}[]} holidays - holiday list
 *
 */
/**
 * BookingLimitConfig object
 *
 * @typedef {Object} BookingLimitConfig
 * @property {{enabled: boolean, includePending:boolean, bookingLimit: number }} holidays - holiday list
 *
 */
/**
 * NotificationConfig object
 *
 * @typedef {Object} NotificationConfig
 * @property {{type: string, method: string}} configKey - NotificationConfigKey containing information about they type and method of the notification
 * @property {boolean} enabled
 * @property {string} template - template content
 *
 */
/**
 * BookingReminder object
 *
 * @typedef {Object} BookingReminder
 * @property {number} hoursBefore - hours before booking date to trigger the notification
 * @property {boolean} smsEnabled
 * @property {string} emailEnabled
 *
 */
/**
 * Auto accept bookings settings dto
 *
 * @typedef {Object} AcceptBookingsDto
 * @property {boolean} autoAcceptManualBooking
 * @property {boolean} autoAcceptAutomaticBooking
 * @property {boolean} rejectDuplicates
 *
 */
/**
 * AvailabilityConfig object
 *
 * @typedef {Object} AvailabilityConfig
 * @property {RepeatingAvailabilityConfig} repeatingAvailabilityConfig
 *
 */
/**
 *
 * @typedef {Object} OrganizationConfigUpdate
 *
 * @property {string} name
 * @property {string} phone
 * @property {string} timezone organization timezone check /constants/timezoneList.js to see all possible values
 * @property {OrganizationAddress} address
 *
 */
/**
 * RepeatingAvailabilityConfig Object
 *
 * @typedef {Object} RepeatingAvailabilityConfig
 * @property {DayAvailability} monday
 * @property {DayAvailability} tuesday
 * @property {DayAvailability} wednesday
 * @property {DayAvailability} thursday
 * @property {DayAvailability} friday
 * @property {DayAvailability} saturday
 * @property {DayAvailability} sunday
 * @property {string} breakStart - Break hour start with format HH:mm:ss:zzz
 * @property {string} breakEnd - break hour end with format HH:mm:ss:zzz
 *
 */
/**
 *
 * Booking Object
 *
 * @typedef {Object} Booking
 * @property {string} id
 * @property {string} bookingCode
 * @property {string} bookingDate - string containing the booking date with format YYYY-MM-DDThh:mm:ss
 * @property {'AUTO' | 'MANUAL'} bookingType - Indicates if the booking was created by a merchant or a customer
 * @property {'PENDING' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'COMPLETED'} bookingState - Booking current state
 * @property {Object.<string, string>} customFields - Booking form fields
 *
 */
/**
 * Dto for merchant booking list response
 *
 * @typedef {Object} BookingPageDto
 * @property {Booking[]} content - string containing the booking date with format YYYY-MM-DDThh:mm:ss
 * @property {number} pageSize
 * @property {number} current
 * @property {number} pageLimit
 * @property {number} totalElements
 */
/**
 * Dto for merchant booking creation
 *
 * @typedef {Object} ManualBookingDto
 * @property {string} bookingDate - string containing the booking date with format YYYY-MM-DDThh:mm:ss
 * @property {Object} customFields - form fields
 * @property {organizationId} organizationId - organization id
 */

/**
 *
 * Dto for reduced organizations info
 *
 * @typedef {Object} OrganizationInfoDto
 * @property {string} name
 * @property {string} id
 *
 */

/**
 *
 * Dto for notification template keywords
 *
 * @typedef {Object} TemplateKeywordsDto
 * @property {string} notificationType -  Notification event type i.e. BOOKING_CREATED
 * @property {{name: string, keyword: string}[]} keywords - keyword array
 *
 */
